/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import {useState} from 'react';
import {
  Text,
  View,
  ScrollView,
  Image,
  StyleSheet,
  SafeAreaView,
  Platform,
  TouchableOpacity,
} from 'react-native';
import {environment} from './../environments/environment.prod';
import {
  TextInput,
  Card,
  Title,
  Button,
  HelperText,
  useTheme,
} from 'react-native-paper';

const ResetPassword = ({route, navigation}) => {
  const {url} = route.params;

  const getToken = () => {
    console.log('URL is ', url);
    const myUrl = url;
    if (myUrl == null) {
      console.log('Result is  null', -99);
      return '';
    }
    const location = myUrl.search('/reset-token/');
    if (location === -1 || !location) {
      console.log('Result is  ', -99);
      return '';
    }
    const entityId = myUrl.substring(location, url.length).split('/');

    console.log('Found Result is  ', entityId[2]);
    return entityId[2];
  };
  const {
    colors: {accent, surface},
  } = useTheme();

  const [username, setUsername] = useState('');
  const [loginToken, setLoginToken] = useState(getToken());
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [genericError, setGenericError] = useState('');
  const [passwordMatch, setPasswordMatch] = useState('');
  const [success, setSuccess] = useState('');

  const resetPassword = async () => {
    if (errorPassword) {
      setGenericError('Password is not valid');
      return;
    }
    if (passwordMatch) {
      setGenericError('Passwords do not match');
      return;
    }
    const json: string =
      '{"UserName": "' +
      username +
      '" , "LoginToken": "' +
      loginToken +
      '", "NewPassword": "' +
      newPassword +
      '" , "ConfirmPassword": "' +
      confirmPassword +
      '"}';
    console.log(json);
    try {
      const response = await fetch(environment.ApiURL + '/auth/passwordreset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: json,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.Success) {
            setGenericError('');
            setSuccess(data.Message);
            alert(data.Message);
            // For testing use localhost
            //TODO: Change link based on publication
            window.location.replace('https://participant.biolinked.org/');
          } else {
            setSuccess('');
            setGenericError(data.Message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } finally {
      console.log('');
    }
  };

  function validatePassword(password: string) {
    setGenericError('');
    let reg = /^(?=.{7,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;
    if (reg.test(password) === true) setErrorPassword('');
    else setErrorPassword('invalid password');

    setNewPassword(password);
  }

  function checkMatch(confirmPassowrd: string) {
    setConfirmPassword(confirmPassowrd);
    if (confirmPassowrd !== newPassword)
      setPasswordMatch('Passwords do not match');
    else setPasswordMatch('');

    setConfirmPassword(confirmPassowrd);
  }

  const navigateToPage = (page: string) => {
    navigation.navigate(page);
  };

  return (
    <SafeAreaView>
      <ScrollView>
        <Card style={styles.card}>
          <View style={styles.logo}>
            {Platform.OS === 'web' ? (
              <TouchableOpacity
                activeOpacity={0.5}
                onPress={() => navigateToPage('Sign In')}>
                <Image
                  style={styles.image}
                  source={require('../assets/logo.png')}
                />
              </TouchableOpacity>
            ) : (
              <Image
                style={styles.image}
                source={require('../assets/logo.png')}
              />
            )}
          </View>
          <Card.Content>
            <Title style={styles.title}>Reset Password</Title>
          </Card.Content>
          <View style={styles.container}>
            <View>
              <TextInput
                textAlign="left"
                mode="outlined"
                style={styles.inputContainerStyle}
                theme={{
                  colors: {
                    text: 'white',
                    primary: accent,
                    background: surface,
                  },
                }}
                label="Username"
                value={username}
                autoCapitalize="none"
                onChangeText={(text) => setUsername(text)}
              />
              {/* <TextInput
                disabled
                textAlign="left"
                mode="outlined"
                theme={{
                  colors: {text: 'white', primary: accent, background: surface},
                }}
                style={styles.inputContainerStyle}
                label="Password Reset Token"
                value={loginToken}
                onChangeText={(text) => setLoginToken(text)}
              /> */}
              <TextInput
                textAlign="left"
                mode="outlined"
                theme={{
                  colors: {text: 'white', primary: accent, background: surface},
                }}
                style={styles.inputContainerStyle}
                label="New Password"
                secureTextEntry={true}
                value={newPassword}
                onChangeText={(text) => validatePassword(text)}
              />
              <TextInput
                textAlign="left"
                mode="outlined"
                theme={{
                  colors: {text: 'white', primary: accent, background: surface},
                }}
                style={styles.inputContainerStyle}
                label="Confirm Password"
                secureTextEntry={true}
                value={confirmPassword}
                onChangeText={(text) => checkMatch(text)}
              />
            </View>
            <Button
              style={styles.buttonAlt}
              mode="contained"
              onPress={() => resetPassword()}>
              <Text>Reset Password</Text>
            </Button>
            <HelperText
              style={styles.error}
              type="error"
              visible={genericError.length != 0}>
              {genericError}
            </HelperText>
            <HelperText
              style={styles.success}
              type="error"
              visible={success.length != 0}>
              {success}
            </HelperText>
            <Button
              style={styles.forgot}
              mode="contained"
              onPress={() =>
                //TODO: Change link based on publication
                window.location.replace('https://participant.biolinked.org/')
              }
              icon="chevron-left">
              <Text>Back</Text>
            </Button>
          </View>
        </Card>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  bgcontainer: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
    marginTop: '5%',
  },
  buttonAlt: {
    margin: 8,
    padding: 10,
    marginTop: 10,
    backgroundColor: '#f1955b',
  },
  card: {
    justifyContent: 'center',
    borderRadius: 30,
    height: 'auto',
    width: '100%',
    minWidth: 350,
    maxWidth: 500,
    margin: 'auto',
    marginTop: 50,
    marginBottom: 30,
    padding: '5%',
  },
  error: {
    fontSize: 14,
    color: '#FF0000',
    paddingLeft: 25,
    paddingTop: 4,
  },
  success: {
    fontSize: 14,
    color: '#c9deea',
    paddingLeft: 25,
    paddingTop: 4,
  },
  container: {
    padding: 8,
  },
  wrapper: {
    flex: 1,
  },
  inputContainerStyle: {
    margin: 8,
  },
  button: {
    margin: 8,
    padding: 10,
  },
  forgot: {
    backgroundColor: 'transparent',
    alignItems: 'flex-start',
    marginLeft: 15,
    fontWeight: '400',
    textTransform: 'capitalize',
  },
  logo: {
    marginBottom: 10,
    zIndex: 999999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 100,
    position: 'relative',
    margin: 'auto',
  },
  image: {
    marginBottom: 10,
    zIndex: 999999,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 100,
    marginTop: '15%',
  },
});

export default ResetPassword;
